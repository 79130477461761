import React from 'react'

export default function Profile() {
  return (
    <div className="dropdown ms-1 ms-lg-0">
    <a className="avatar avatar-sm p-0" href="#" id="profileDropdown" role="button" data-bs-auto-close="outside" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
        <img className="avatar-img rounded-circle" src="assets/images/avatar/01.jpg" alt="avatar"/>
    </a>
    <ul className="dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3" aria-labelledby="profileDropdown">
        {/* <!-- Profile info --> */}
        <li className="px-3">
            <div className="d-flex align-items-center">
                {/* <!-- Avatar --> */}
                <div className="avatar me-3">
                    <img className="avatar-img rounded-circle shadow" src="assets/images/avatar/01.jpg" alt="avatar"/>
                </div>
                <div>
                    <a className="h6" href="#">Lori Ferguson</a>
                    <p className="small m-0">example@gmail.com</p>
                </div>
            </div>
            <hr/>
        </li>
        {/* <!-- Links --> */}
        <li><a className="dropdown-item" href="#"><i className="bi bi-person fa-fw me-2"></i>Edit Profile</a></li>
        <li><a className="dropdown-item" href="#"><i className="bi bi-gear fa-fw me-2"></i>Account Settings</a></li>
        <li><a className="dropdown-item" href="#"><i className="bi bi-info-circle fa-fw me-2"></i>Help</a></li>
        <li><a className="dropdown-item bg-danger-soft-hover" href="#"><i className="bi bi-power fa-fw me-2"></i>Sign Out</a></li>
    </ul>
</div>
  )
}
