import {Route, Routes} from 'react-router-dom'
import BottomNavigation from './components/BottomNavigation';
import Homepage from './page/Homepage';
import CountactUs from './page/ContactUs';
import NotFound from './page/NotFound';
import ListCampus from './page/Campus/ListCampus';
import DetailCampus from './page/Campus/DetailCampus';

function App() {
  return (
    <>
    <Routes>
      <Route path='/' index element={<Homepage/>} />
      <Route path='kontak-kami' element={<CountactUs/>} />
      <Route path='campuses' element={<ListCampus/>}/>
      <Route path='*' element={<NotFound/>} />
    </Routes>
    <BottomNavigation />
    </>
  );
}

export default App;
