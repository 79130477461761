import React from "react";

export default function CardScholarship(props) {
  const { data } = props;

  return (
    // <div className="col-md-6 col-lg-4 col-xxl-3 " key={data.id}>
    <div className="card p-2  h-100 m-2">
      <div className="rounded-top overflow-hidden p-2">
        {/* <!-- Hover element --> */}
        <div className="card-img-overlay">
          <div className="d-flex justify-content-end">
            <div className="text-white rounded p-1 bg-success">Dibuka</div>
          </div>
        </div>

        <div className="position-relative">
          {/* <!-- Image --> */}
          <img
            src="assets/images/courses/4by3/21.jpg"
            className="card-img"
            alt="course image"
          />
          {/* <!-- Overlay --> */}
          <div className="card-img-overlay d-flex align-items-start flex-column p-3">
            <div className="w-100 mt-auto">
              <div className="d-flex justify-content-between">
                {/* <!-- Rating and info --> */}
                <ul className="list-inline hstack">
                  {/* <!-- Info --> */}
                  <li className="list-inline-item d-flex justify-content-center align-items-center">
                    <div
                      className="icon-md bg-success bg-opacity-2 text-white rounded-circle"
                      style={{
                        height: "25px",
                        width: "25px",
                        lineHeight: "20px",
                        padding: "2px",
                        textAlign: "center",
                      }}
                    >
                      S1
                    </div>
                  </li>
                  {/* <!-- Rating --> */}
                  <li className="list-inline-item d-flex justify-content-center align-items-center">
                    <div
                      className="icon-md bg-success bg-opacity-2 text-white rounded-circle"
                      style={{
                        height: "25px",
                        width: "25px",
                        lineHeight: "20px",
                        padding: "2px",
                        textAlign: "center",
                      }}
                    >
                      S2
                    </div>
                  </li>
                  <li className="list-inline-item d-flex justify-content-center align-items-center">
                    <div
                      className="icon-md bg-success bg-opacity-2 text-white rounded-circle"
                      style={{
                        height: "25px",
                        width: "25px",
                        lineHeight: "20px",
                        padding: "2px",
                        textAlign: "center",
                      }}
                    >
                      S3
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Card body --> */}
      <div className="card-body px-2">
        {/* <!-- Divider --> */}
        {/* <!-- Title --> */}
        <p className="card-title">
          Beasiswa Partial
        </p>
        <h6 className="card-title">
          <a href="#">Djarum Beasiswa Plus</a>
        </h6>
        <hr />
        {/* <!-- Badge and Price --> */}
        <div
          className="d-flex justify-content-between align-items-center mb-0"
          style={{ fontSize: "12px" }}
        >
          <div>
            <div className="text-black me-2">
              <strong>Pendaftaran</strong>
            </div>
          </div>
          {/* <!-- Price --> */}
          <p className="text-black mb-0">26 Desember 2022</p>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-0">
          <div>
            <div className="text-danger me-2">
              <strong>Berakhir</strong>
            </div>
          </div>
          {/* <!-- Price --> */}
          <p className="text-danger mb-0">26 Mei 2023</p>
        </div>
      </div>
    </div>
    // </div>
  );
}
