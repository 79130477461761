import React from "react";

export default function CardCampus(props) {
  const { data } = props;

  return (
    <div>
      <div
        className="card border mb-1 p-2 m-2 bg-transparent"
        style={{ height: "380px" }}
      >
        {/* <!-- Card image --> */}
        <img
          className="card-img-top"
          src="https://images.unsplash.com/photo-1497211419994-14ae40a3c7a3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8Ymx1cnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
          alt="Card image"
        />
        {/* <!-- Card body --> */}
        <div className="card-img-overlay d-flex flex-column p-3">
          <div className="w-100 mt-auto text-end mt-n1">
            <div className="badge text-white bg-edu-primary-300 rounded-1">
              {data.category}
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mt-n6 mb-3">
            {/* <!-- Logo image --> */}
            <div className="bg-white p-2 rounded-2 shadow">
              <img
                className="rounded-1 h-60px"
                src={data.logo}
                alt="university logo"
              />
            </div>
            {/* <!-- Badge --> */}
            <div className="h6 mb-0">
              {data.status === 'open' ? (
                <a href="#" className="badge bg-info text-white">
               Pendaftaran Buka
              </a>
              ): (
                <a href="#" className="badge bg-danger text-white">
                Pendaftaran Tutup
              </a>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between mb-3"></div>
          {/* <!-- Title --> */}
          <h6 className="card-title text-justify font-weight-bold">
            <a href="#">{data.name}</a>
          </h6>
          <p className="mb-2">
            <i className="fas fa-map-marker-alt me-2"></i> {data.city},{" "}
            {data.province}{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
