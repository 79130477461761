import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="bg-edu-primary-200 pt-5 bg-edu-primary-000">
      <div className="container">
        {/* <!-- Row START --> */}
        <div className="row g-4">
          {/* <!-- Widget 1 START --> */}
          <div className="col-lg-3">
            {/* <!-- logo --> */}
            <a className="me-0" href="index.html">
              {/* <img
                className="h-40px"
                src="assets/images/logo-light.svg"
                alt="logo"
              /> */}
              <h4 className="text-white"> EDUKASI HUB</h4>
            </a>
            <p className="my-3 text-muted">
              Edukasi Hub is education theme, built specifically for the
              education centers which is dedicated to teaching and involving
              learners.{" "}
            </p>
            {/* <!-- Social media icon --> */}
            <ul className="list-inline mb-0 mt-3">
              <li className="list-inline-item">
                {" "}
                <Link
                  className="btn btn-white btn-sm shadow px-2 text-facebook"
                  to={"/facebook"}
                >
                  <i className="fab fa-fw fa-facebook-f"></i>
                </Link>{" "}
              </li>
              <li className="list-inline-item">
                {" "}
                <Link
                  className="btn btn-white btn-sm shadow px-2 text-facebook"
                  to={"/facebook"}
                >
                  <i className="fab fa-fw fa-instagram"></i>
                </Link>{" "}
              </li>
              <li className="list-inline-item">
                {" "}
                <Link
                  className="btn btn-white btn-sm shadow px-2 text-facebook"
                  to={"/ig"}
                >
                  <i className="fab fa-fw fa-linkedin"></i>
                </Link>{" "}
              </li>
            </ul>
          </div>
          {/* <!-- Widget 1 END --> */}

          {/* <!-- Widget 2 START --> */}
          <div className="col-lg-6">
            <div className="row g-4">
              {/* <!-- Link block --> */}
              <div className="col-6 col-md-4">
                <h5 className="mb-2 mb-md-4 text-white">Edukasi Hub</h5>
                <ul className="nav flex-column  text-white">
                  <li className="nav-item">
                    <Link className="nav-link text-white" to={"/"}>
                      Tentang Kami
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-white" to={"/"}>
                      Faqs
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-white" to={"/"}>
                      Terms of Service
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-white" to={"/"}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-white" to={"/"}>
                      Karir
                    </Link>
                  </li>
                </ul>
              </div>

              {/* <!-- Link block --> */}
              <div className="col-6 col-md-4">
                <h5 className="mb-2 mb-md-4 text-white">Layanan</h5>
                <ul className="nav flex-column text-primary-hover">
                  <li className="nav-item">
                    <Link className="nav-link text-white" to={"/"}>
                      Info Kampus
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-white" to={"/"}>
                      Jalur Masuk
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-white" to={"/"}>
                      Beasiswa
                    </Link>
                  </li>
                </ul>
              </div>

              {/* <!-- Link block --> */}
              {/* <div className="col-6 col-md-4">
                <h5 className="mb-2 mb-md-4 text-white">Teaching</h5>
                <ul className="nav flex-column text-primary-hover">
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      Become a teacher
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      How to guide
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      Terms &amp; Conditions
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          {/* <!-- Widget 2 END --> */}

          {/* <!-- Widget 3 START --> */}
          <div className="col-lg-3">
            <h5 className="mb-2 mb-md-4 text-white">Kontak Kami</h5>
            {/* <!-- Time --> */}
            <p className="mb-2 text-muted">
              Toll free:
              <span className="h6 fw-light text-white ms-2">+62 568 963</span>
              <span className="d-block small">(9:AM to 8:PM IST)</span>
            </p>

            <p className="mb-0 text-muted">
              Email:
              <span className="h6 fw-light text-white ms-2">
                example@gmail.com
              </span>
            </p>

            {/* <!-- Row END --> */}
          </div>
          {/* <!-- Widget 3 END --> */}
        </div>
        {/* <!-- Row END --> */}

        {/* <!-- Divider --> */}
        <hr className="mt-4 mb-0" />

        {/* <!-- Bottom footer --> */}
        <div className="p-1">
          <div className="container px-1">
            <div className="d-md-flex justify-content-between align-items-center py-4 text-center text-md-left">
              {/* <!-- copyright text --> */}
              <div className="text-muted text-primary-hover">
                {" "}
                Copyrights{" "}
                <a href="/" className="text-reset">
                  ©2021 Edukasi Hub
                </a>
                . All rights reserved.{" "}
              </div>
              {/* <!-- copyright links--> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
