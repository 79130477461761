import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import SectionBannerTop from "../../components/Section/SectionBannerTop";
import ReactPaginate from "react-paginate";
import CardCampus from "../../parts/CardCampus";
import Form from "react-bootstrap/Form";
import CardLoading from "../../parts/CardLoading";

const dataSectionBannerTop = {
  title: "Info Kampus",
  desc: " Dapatkan informasi terlengkap seputar kampus nasional dan internasional, mulai dari periode pendaftaran hingga detail syarat dokumen yang dibutuhkan.",
  parentBreadcrumb: "Home",
  chilBreadcrumb: "Info Kampus",
};
export default function ListCampus() {
  const [images, setImages] = useState([]);

  // useEffect(() => {
  //   fetch("https://jsonplaceholder.typicode.com/albums/1/photos")
  //     .then((response) => response.json())
  //     .then((data) => setImages(data));
  // }, []);

  useEffect(() => {
    fetch("https://api-santrihub.cyclic.app/api/kampus")
      .then((response) => response.json())
      .then((data) => setImages(data.result.data));
  }, []);

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemPerPage = 4;

  useEffect(() => {
    const endOffset = itemOffset + itemPerPage;
    setCurrentItems(images.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(images.length / itemPerPage));
    window.scrollTo({ top: 10 });
  }, [images, itemOffset, itemPerPage]);

  const handlePageClick = (e) => {
    console.log(e, "klik");
    const newOffset = (e.selected * itemPerPage) % images.length;
    console.log(newOffset, "newOffset");
    setItemOffset(newOffset);
  };

  return (
    <>
      <Navbar />
      <main className="bg-edu-neutral-600 pt-5 pt-lg-6">
        <SectionBannerTop data={dataSectionBannerTop} />

        <section className="py-4 pb-8">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-xl-9">
                <div className="row mb-4 align-items-center">
                  <div className="col-xl-12">
                    <form className="border rounded p-2">
                      <div className="input-group input-borderless">
                        <input
                          className="form-control me-1"
                          type="search"
                          placeholder="Cari Nama Kampus"
                        />
                        <button
                          type="button"
                          className="btn btn-primary mb-0 rounded z-index-1"
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="col-12 col-xl-3 d-flex justify-content-between align-items-center mt-3 mt-xl-0">
                    {/* <!-- Advanced filter responsive toggler START --> */}
                    <button
                      className="btn btn-primary mb-0 d-lg-none"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasNavbar"
                      aria-controls="offcanvasNavbar"
                    >
                      <i className="fas fa-sliders-h me-1"></i>Filter
                    </button>
                  </div>
                </div>
                <div className="row g-4">
                  {currentItems?.length > 0 ? (
                    currentItems.map((item, i) => (
                      <div className="col-sm-6 col-xl-4" key={i}>
                        <CardCampus data={item} />
                      </div>
                    ))
                  ) : (
                    <CardLoading data={[1, 2, 3, 4, 5, 6]} />
                  )}
                </div>

                <div className="col-12">
                  <nav
                    className="mt-7 d-flex justify-content-center "
                    aria-label="navigation"
                  >
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      previousLabel="<"
                      onPageChange={handlePageClick}
                      pageCount={pageCount}
                      renderOnZeroPageCount={null}
                      containerClassName="gap-1 pagination pagination-primary-soft pagination-m d-md-flex rounded mb-0"
                      pageLinkClassName="page-item page-link"
                      previousClassName="page-item page-link"
                      nextLinkClassName="page-item page-link"
                      activeClassName="active"
                    />
                  </nav>
                </div>
              </div>

              <div className="col-lg-4 col-xl-3">
                {/* <!-- Responsive offcanvas body START --> */}
                <nav className="navbar navbar-light navbar-expand-lg mx-0">
                  <div
                    className="offcanvas offcanvas-end"
                    tabIndex="1"
                    id="offcanvasNavbar"
                    aria-labelledby="offcanvasNavbarLabel"
                  >
                    <div className="offcanvas-header bg-light">
                      <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                        Advance Filter
                      </h5>
                      <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="offcanvas-body p-3 p-lg-0">
                      <form>
                        {/* <!-- Category START --> */}
                        <div className="card card-body shadow p-4 mb-4">
                          {/* <!-- Title --> */}
                          <h5 className="mb-3">Kategori Kampus</h5>
                          {/* <!-- Category group --> */}
                          <div className="col-12">
                            {/* <!-- Checkbox --> */}
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault9"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault9"
                                >
                                  Semua Kampus
                                </label>
                              </div>
                            </div>
                            {/* <!-- Checkbox --> */}
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault10"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault10"
                                >
                                  PTN
                                </label>
                              </div>
                            </div>
                            {/* <!-- Checkbox --> */}
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault11"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault11"
                                >
                                  PTKIN
                                </label>
                              </div>
                            </div>
                            {/* <!-- Checkbox --> */}
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault12"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault12"
                                >
                                  PTK
                                </label>
                              </div>
                            </div>
                            {/* <!-- Checkbox --> */}
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault17"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault17"
                                >
                                  PTKIN
                                </label>
                              </div>
                            </div>
                            {/* <!-- Checkbox --> */}
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault13"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault13"
                                >
                                  PTS
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- Category END --> */}

                        {/* <!-- Akreditasi START --> */}
                        {/* <div className="card card-body shadow p-4 mb-4">
                          <h4 className="mb-3">Akreditasi</h4>
                          <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                              <input
                                type="radio"
                                className="btn-check"
                                name="options"
                                id="option1"
                              />
                              <label
                                className="btn btn-light btn-primary-soft-check"
                                htmlFor="option1"
                              >
                                Semua
                              </label>
                            </li>
                            <li className="list-inline-item">
                              <input
                                type="radio"
                                className="btn-check"
                                name="options"
                                id="option2"
                              />
                              <label
                                className="btn btn-light btn-primary-soft-check"
                                htmlFor="option2"
                              >
                                A
                              </label>
                            </li>
                            <li className="list-inline-item">
                              <input
                                type="radio"
                                className="btn-check"
                                name="options"
                                id="option3"
                              />
                              <label
                                className="btn btn-light btn-primary-soft-check"
                                htmlFor="option3"
                              >
                                B
                              </label>
                            </li>
                          </ul>
                        </div> */}
                        {/* <!-- Akreditasi END --> */}

                        {/* <!-- Skill level START --> */}
                        <div className="card card-body shadow p-4 mb-4">
                          {/* <!-- Title --> */}
                          <h5 className="mb-3">Provinsi</h5>
                          <Form.Select aria-label="Default select example">
                            <option>Pilih Provinsi</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                        {/* <!-- Skill level END --> */}

                        {/* <!-- Language START --> */}
                        <div className="card card-body shadow p-4 mb-4">
                          {/* <!-- Title --> */}
                          <h5 className="mb-3">Kota / Kabupaten</h5>
                          <Form.Select aria-label="Default select example">
                            <option>Pilih Kota / Kabupaten</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </Form.Select>
                        </div>
                        {/* <!-- Language END --> */}
                        <div className="d-grid p-2 p-lg-0 text-center">
                          <button className="btn btn-primary mb-0">
                            Terapkan
                          </button>
                        </div>
                      </form>
                      {/* <!-- Form End --> */}
                    </div>
                  </div>
                </nav>
                {/* <!-- Responsive offcanvas body END --> */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
