import React from "react";

export default function CardLoading(props) {
  const {data}= props

  return data.map((item) => (
    <div className="col-sm-4 col-xl-4 card__load" key={item}>
      <div className="card__image"></div>
      <div className="card__content">
        <h2></h2>
        <p></p>
      </div>
    </div>
  ));
}
