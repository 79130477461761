import React from 'react'
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import CardMitra from '../parts/CardMitra';

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      paritialVisibilityGutter: 30,
    },
  };






// assets/images/client/google.svg
// assets/images/client/linkedin.svg

const data = [
    {
        id:1,
        src:'assets/images/client/coca-cola.svg'
    },
    {
        id:2,
        src:'assets/images/client/android.svg'
    },
    {
        id:3,
        src:'assets/images/client/envato.svg'
    },
    {
        id:4,
        src:'assets/images/client/microsoft.svg'
    },
    {
        id:5,
        src:'assets/images/client/netflix.svg'
    }

]
export default function Mitra(props) {
    
const {deviceType} = props
  return (
    <section className="py-5 bg-edu-neutral-600">
	<div className="container">
    <Carousel
        ssr
        partialVisibilityGutter
        deviceType={deviceType}
        itemClass="image-item"
        responsive={responsive}
        className=""
      >
        {data.map((item) => {
          return (
           <div key={item.id} className="row justify-content-center my-4">
            <CardMitra data={item}/>
           </div>
          );
        })}
      </Carousel>
	</div>
</section>
  )
}
