import React, { useState, useEffect } from "react";

import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import PrimaryFeature from "../components/PrimaryFeature";
import Campus from "../components/Campus";
import Scholarship from "../components/Scholarship";
import Mitra from "../components/Mitra";
import Footer from "../components/Footer";

function Homepage() {
  const [width, setWidth] = useState(window.innerWidth);

  const [connect, setConnect] = useState(null);


  // connect API
  useEffect(() => {
    fetch("https://api-santrihub.cyclic.app/api-connect")
      .then((response) => response.json())
      .then((data) => setConnect(data));
  },[]);

  console.log(connect?.success);
  if(!connect?.success) {
    console.log(connect?.message)
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let deviceType;
  if (width <= 768) {
    deviceType = "mobile";
  } else if (width > 768 && width <= 992) {
    deviceType = "tablet";
  } else {
    deviceType = "desktop";
  }

  return (
    <>
      <Navbar />
      <main>
        <Hero />
        <PrimaryFeature />
        <Campus deviceType={deviceType} />
        <Scholarship deviceType={deviceType} />
        <Mitra deviceType={deviceType} />
        <Footer />
      </main>
    </>
  );
}

export default Homepage;
