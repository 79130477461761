import React from 'react'

export default function CardMitra(props) {
    const {data} = props
  return (
    <div className="item" style={{width:'75%'}}> 
        <img src={data.src} alt=""/> 
    </div>
    )
}
