import React from "react";
import { Link } from "react-router-dom";

export default function SectionBannerTop(props) {
  const { title, desc, parentBreadcrumb, chilBreadcrumb } = props.data;
  
  return (
    <section
      className="bg-blue align-items-center d-flex pt-6"
      style={{
        backgroundImage: `url("assets/images/pattern/04.png")`,
        backgroundRepeat: "no-repeat center",
        backgroundSize: "cover",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 text-left">
            {/* <!-- Title --> */}
            <h1 className="text-white">{title}</h1>
            <p className="text-white">{desc}</p>
            {/* <!-- Breadcrumb --> */}
            <div className="d-flex justify-content-left">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-dark breadcrumb-dots mb-0">
                  <Link className="breadcrumb-item text-white" to={"/"}>
                    {parentBreadcrumb}
                  </Link>
                  <li
                    className="breadcrumb-item text-warning active"
                    aria-current="page"
                  >
                    {chilBreadcrumb}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
