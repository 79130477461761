/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom";

import Profile from './Profile';

const hasWindow = typeof window !== 'undefined';

function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }
  
export default function Navbar() {
    const user = localStorage.getItem('user');
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        if (hasWindow) {
          function handleResize() {
            setWindowDimensions(getWindowDimensions());
          }
    
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        }
      }, []);
    
  return (
    <header className="navbar-light bg-edu-primary-000 navbar-sticky header-static fixed-top" style={{height:'80px'}} >
      {/* <!-- Logo Nav START --> */}
      <nav className="navbar navbar-expand-xl" style={{marginTop: '-5px'}}>
        <div className="container">
          {/* <!-- Logo START --> */}
          <a className="navbar-brand" href="index.html">
            {/* <img
              className="light-mode-item navbar-brand-item"
              src="assets/images/logo.svg"
            
              alt="logo"
            /> */}
            <h4>Edukasi Hub</h4>
            <img
              className="dark-mode-item navbar-brand-item"
              src="assets/images/logo-light.svg"
              alt="logo"
            />
          </a>
          {/* <!-- Logo END --> */}

          {/* <!-- Responsive navbar toggler --> */}
          <button
            className="navbar-toggler ms-auto m-1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-animation m-1"  >
              <span></span>
              <span></span>
              <span></span>
            </span>
            
          </button>
          {windowDimensions.width <= 991 && windowDimensions.width >= 399 && !user ? (
              <>
                <div
                  role={"button"}
                  className="btn btn-info-soft text-edu-primary-300 bg-edu-primary-000 border-edu-primary-100  d-sm-inline-block mb-1" style={{marginRight: '-20px !important'}}
                >
                  {/* <i className=" "></i> */}
                  <Link to={'/login'}>Masuk</Link>
                </div>
                <div
                  role={"button"}
                  className="btn btn-primary bg-edu-primary-300 border-edu-primary-100 mb-1 m-1"
                >
                    <Link to={'/register'} className='text-white' >Daftar</Link>
                </div>
              </>
            ) : (
              ""
            )}

          {windowDimensions.width < 399 && !user ? (
              <>
                <div
                  role={"button"}
                  className="btn btn-info-soft text-edu-primary-300 bg-edu-primary-000 border-edu-primary-100  d-sm-inline-block mb-1" style={{marginRight: '-20px !important'}}
                >
                 <Link to={'/login'}>Masuk</Link>
                </div>
              </>
            ) : (
              ""
            )}

          {/* <!-- Main navbar START --> */}
          <div className="navbar-collapse w-100 collapse" id="navbarCollapse">
            {/* <!-- Nav Main menu START --> */}
            <ul className="navbar-nav navbar-nav-scroll mx-auto">
              {/* <!-- Nav item 1 Demos --> */}
              <li className="nav-item">
                {/* <a href="docs/alerts.html">
                  Home
                </a> */}
                <Link className="nav-link active" to={"/"}>Home</Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle "
                  href="/sds"
                  id="demoMenu"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  Tentang Kami
                </a>
                <ul className="dropdown-menu" aria-labelledby="demoMenu">
                  <li>
                    {" "}
                    <Link className="dropdown-item"  to={'/vision'}>Our Vision</Link>
                  </li>
                  <li>
                    {" "}
                    <Link className="dropdown-item"  to={'/value'}>Our Value</Link>
                  </li>
                  <li>
                    {" "}
                    <Link className="dropdown-item"  to={'/teams'}>Our Teams</Link>
                  </li>
                </ul>
              </li>

              {/* <!-- Nav item 2 Pages --> */}
              <li className="nav-item">
                <Link to={'/campuses'} className='nav-link'>
                  Info Kampus
                </Link>
              </li>

              {/* <!-- Nav item 3 Account --> */}
              <li className="nav-item">
                <Link to={'/scholarships'} className='nav-link'>
                  Beasiswa
                </Link>
              </li>

              {/* <!-- Nav item 4 Component--> */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="accounntMenu"
                  data-bs-toggle="dropdown"
                >
                  Kalender
                </a>
                <ul className="dropdown-menu" aria-labelledby="accounntMenu">
                  <li>
                    {" "}
                    <Link  className="dropdown-item" to={'/kalender/beasiswa'}>
                      Beasiswa
                    </Link>
                    {" "}
                  </li>
                  <li>
                    {" "}
                    <Link  className="dropdown-item" to={'/kalender/jalur-masuk'}>
                      Jalur Masuk
                    </Link>
                    {" "}
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link to={'/kontak-kami'} className='nav-link'>
                  Kontak Kami
                </Link>
              </li>
            </ul>
            {/* <!-- Nav Main menu END --> */}
          </div>
          {/* <!-- Main navbar END --> */}
          {/* Login & Register */}

          {user ? (
            <>
              <Profile />
            </>
          ) : (
            <>
              <div className="navbar-nav d-none d-lg-inline-block m-2">
                <button className="btn btn-info-soft text-edu-primary-300 bg-edu-primary-000 border-edu-primary-100  mb-0">
                <Link to={'/login'}>Masuk</Link>
                </button>
              </div>
              <div className="navbar-nav d-none d-lg-inline-block ">
                <button className="btn btn-primary bg-edu-primary-300  border-edu-primary-100 mb-0">
                <Link to={'/register'} className='text-white' >Daftar</Link>
                </button>
              </div>
            </>
          )}
        </div>
      </nav>
      {/* <!-- Logo Nav END --> */}
    </header>
  );
}
