/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import CardCampus from "../parts/CardCampus";
import { Link } from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

const data = [
  {
    id: 7,
    name: "Universitas Indonesia",
    slug: "universitas-indonesia",
    logo: "https://storage.googleapis.com/glide-prod.appspot.com/uploads-v2/8HHdusGdib9i8ccMjjcV/pub/KypZh77alRKGz9X1pppO.jpeg",
    city: "Kota Depok",
    province: "Jawa Barat ",
    category: "PTN",
    status: "open",
    faculties: [],
  },
  {
    id: 8,
    name: "Institut Teknologi Sepuluh November",
    slug: "institut-teknologi-sepuluh-november",
    logo: "https://storage.googleapis.com/glide-prod.appspot.com/uploads-v2/vTdvhVHQ9rbvh0rezQ6F/pub/g1URokpCrfuYLmAFamHg.png",
    city: "Kota Surabaya",
    province: "Jawa Timur ",
    category: "PTN",
    status: "open",
    faculties: [],
  },
  {
    id: 13,
    name: "Universitas Nahdlatul Ulama Surabaya",
    slug: "universitas-nahdlatul-ulama-surabaya",
    logo: "https://unusa.ac.id/wp-content/uploads/2022/03/logo-unusa.jpg",
    city: "Kota Surabaya",
    province: "Jawa Timur ",
    category: "PTS",
    status: "closed",
    faculties: [],
  },
  {
    id: 9,
    name: "Universitas Pendidikan Indonesia",
    slug: "universitas-pendidikan-indonesia",
    logo: "https://storage.googleapis.com/glide-prod.appspot.com/uploads-v2/8HHdusGdib9i8ccMjjcV/pub/yZPTQtcMl9uWKrvnMUsM.png",
    city: "Kab. Bandung",
    province: "Jawa Barat ",
    category: "PTN",
    status: "open",
    faculties: [],
  },
  {
    id: 10,
    name: "Universitas Padjadjaran",
    slug: "universitas-padjadjaran",
    logo: "https://storage.googleapis.com/glide-prod.appspot.com/uploads-v2/8HHdusGdib9i8ccMjjcV/pub/PgWl8ZJEkQ9wO33PFHH8.png",
    city: "Kab. Bandung",
    province: "Jawa Barat ",
    category: "PTN",
    status: "open",
    faculties: [],
  },
  {
    id: 11,
    name: "Politeknik Kesejahteraan Sosial",
    slug: "politeknik-kesejahteraan-sosial",
    logo: "https://storage.googleapis.com/glide-prod.appspot.com/uploads-v2/vTdvhVHQ9rbvh0rezQ6F/pub/vs9yMekwhhspe2v3OJkn.png",
    city: "Kab. Bogor",
    province: "Jawa Barat ",
    category: "PTK",
    status: "open",
    faculties: [],
  },
  {
    id: 12,
    name: "Sekolah Tinggi Ilmu Statistik",
    slug: "sekolah-tinggi-ilmu-statistik",
    logo: "https://storage.googleapis.com/glide-prod.appspot.com/uploads-v2/vTdvhVHQ9rbvh0rezQ6F/pub/VzpRtOgtnDZFjynM6WAT.png",
    city: "Kota Jakarta Selatan",
    province: "DKI Jakarta ",
    category: "PTK",
    status: "open",
    faculties: [],
  },
];

export default function Campus(props) {
  const { deviceType } = props;

  return (
    <>
      <section className="bg-edu-neutral-600">
        <div className="container ">
          <h2 className="fs-3 fw-bold text-center mb-4">
            <span className="position-relative z-index-9">Kampus Populer</span>
          </h2>

          <Carousel
            ssr
            partialVisibilityGutter
            deviceType={deviceType}
            itemClass="image-item"
            responsive={responsive}
            className=""
          >
            {data.map((item) => {
              return (
                <div key={item.id}>
                  <CardCampus data={item} />
                </div>
              );
            })}
          </Carousel>
        </div>
        <p className="text-center text-white font-weight-bold mt-4">
          <Link to={"/campuses"}>
            <span className="position-relative text-white bg-edu-primary-300 p-2 rounded z-index-4">
              Selengkapnya
            </span>
          </Link>
        </p>
      </section>
    </>
  );
}
