import React from 'react'

export default function PrimaryFeature() {
  return (
    <section className='position-relative overflow-hidden pt-5 pt-lg-3 bg-edu-neutral-600'>
	<div className="container">
		<div className="row g-5 align-items-center">
			<div className="col-lg-5 ">
				{/* <!-- Title --> */}
				<h2>Cari fitur yang kamu <br /> butuhkan<span className="text-edu-primary-300 m-2">disini</span></h2>
				{/* <!-- Image --> */}
				<img src="assets/images/about/03.jpg" className="rounded-2" alt=""/>
			</div>
			<div className="col-lg-7">
				<div className="row g-4">
					{/* <!-- Item --> */}
					<div className="col-sm-6">
						<div className="icon-lg bg-orange bg-opacity-10 text-orange rounded-2"><i className="fas fa-university fs-5"></i></div>
						<h5 className="mt-2">Info Kampus</h5>
						<p className="mb-0">In no impression assistance contrasted Manners she wishing justice hastily new anxious At discovery objection we</p>
					</div>
					{/* <!-- Item --> */}
					<div className="col-sm-6">
						<div className="icon-lg bg-info bg-opacity-10 text-info rounded-2"><i className="fas fa-book fs-5"></i></div>
						<h5 className="mt-2">Jalur Masuk</h5>
						<p className="mb-0">Smile spoke total few great had never their too Amongst moments do in arrived at my replied Fat weddings believed prospect</p>
					</div>
					{/* <!-- Item --> */}
					<div className="col-sm-6">
						<div className="icon-lg bg-success bg-opacity-10 text-success rounded-2"><i className="fas fa-graduation-cap fs-5"></i></div>
						<h5 className="mt-2">Beasiswa</h5>
						<p className="mb-0">Denote simple fat denied add worthy little use As some he so high down am week Conduct denied add worthy little use As</p>
					</div>
					{/* <!-- Item --> */}
					<div className="col-sm-6">
						<div className="icon-lg bg-purple bg-opacity-10 text-purple rounded-2"><i className="fas fa-users fs-5"></i></div>
						<h5 className="mt-2">Komunitas</h5>
						<p className="mb-0">Pleasure and so read the was hope entire first decided the so must have as on was want up of to traveling so all</p>
					</div>		
				</div>
			</div>
		</div>
	</div>
</section>
  )
}
