/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'

export default function Hero() {
  return (
    <section className="position-relative overflow-hidden pt-5 pt-lg-6 bg-edu-primary-000" 
	>

	{/* <!-- Content START --> */}
	<div className="container">
		<div className="row align-items-center justify-content-xl-between g-4 g-md-5">
			{/* <!-- Left content START --> */}
			<div className="col-lg-7 col-xl-6 position-relative z-index-1 text-center  text-lg-start mb-2 mb-md-9 mb-xl-0">
				{/* <!-- Badge --> */}
				{/* <h6 className="mb-3 font-base bg-primary bg-opacity-10 text-primary py-2 mt-2 px-4 rounded-2 rounded-pill d-inline-block">Get started with Eduport</h6> */}
                <div className="d-inline-block bg-edu-primary-500 px-3 py-2 mt-5 rounded-pill mb-3">
						<p className="mb-0 text-dark"><span className="badge bg-edu-secondary-100 rounded-pill me-1">Edukasi Hub</span>Ekosistem Pendidikan Terintegerasi</p>
					</div>
				{/* <!-- Title --> */}
				<h1 className="mb-3 display-10">Mari Bergabung dengan
					<span className="position-relative d-inline-block text-edu-primary-300 m-2">Edukasi Hub
					</span>
				</h1>
				{/* <!-- Content --> */}
				<p className="mb-4 display-7">The most reliable online courses and certifications in marketing, information technology, programming, and data science.</p>

				
				{/* <!-- Counter START --> */}
				<div className="row g-3 mb-3 mb-lg-0  p-1 mt-2 rounded" style={{width:'100%'}}>
					{/* <!-- Item --> */}
					<div className="col-sm-6 mt-2">
						<div className="d-flex align-items-center">
							{/* <!-- Icon --> */}
							<div className="icon-lg fs-4 text-orange bg-orange bg-opacity-10 rounded"> <i className="bi bi-book-half"></i> </div>
							{/* <!-- Info --> */}
							<div className="ms-3">
								<div className="d-flex">
									<h4 className="purecounter fw-semibold mb-0 display-9" data-purecounter-start="0" data-purecounter-end="600" data-purecounter-delay="100">600</h4>
									<span className="h4 mb-0">+</span>
								</div>
								<div>Informasi Beasiswa</div>
							</div>
						</div>
					</div>
					{/* <!-- Item --> */}
					<div className="col-sm-6 mt-2">
						<div className="d-flex align-items-center">
							{/* <!-- Icon --> */}
							<div className="icon-lg fs-4 text-info bg-info bg-opacity-10 rounded"> <i className="fas fa-university"></i> </div>
							{/* <!-- Info --> */}
							<div className="ms-3">
								<div className="d-flex">
									<h4 className="purecounter fw-bold mb-0" data-purecounter-start="0" data-purecounter-end="400" data-purecounter-delay="100">100</h4>
									<span className="h4 mb-0">+</span>
								</div>
								<div>Universitas</div>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- Counter END --> */}
			</div>
			{/* <!-- Left content END --> */}

			{/* <!-- Right content START --> */}
			<div className="col-lg-5 col-xl-5 text-center position-relative" >
				{/* <!-- SVG decoration --> */}
				<figure className="position-absolute top-100 start-0 translate-middle mt-n6 ms-5 ps-5 d-none d-md-block">
					<svg width="297.5px" height="295.9px">
					<path stroke="#F99D2B" fill="none" strokeWidth="13" d="M286.2,165.5c-9.8,74.9-78.8,128.9-153.9,120.4c-76-8.6-131.4-78.2-122.8-154.2C18.2,55.8,87.8,0.3,163.7,9"></path>
					</svg>
				</figure>

				<div className="position-relative">
					{/* <!-- Yellow background --> */}
					<div className="bg-warning rounded-4 border border-white border-5 h-200px h-sm-300px shadow"></div>
					{/* <!-- Image --> */}
					<img src="assets/images/element/06.png" className="position-absolute bottom-0 start-50 translate-middle-x"  alt="Image"/>
				</div>

			</div>
			{/* <!-- Right content END --> */}
		</div>
	</div>
	{/* <!-- Content END --> */}
</section>
  )
}
